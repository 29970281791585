import React from "react";
import NewsList from "../../entities/Task/ui/NewsList";
import "./style.css";
import {translate} from "../../shared/utils/translator";

const NewsPage = () => {
    const totalElements = 25;

    return (
        <section className="news-page-container no-scrollbar">
            <h1 className="news-title">{translate("news-title-news-page")} {totalElements} </h1>
            <NewsList />
        </section>
    );
}

export default NewsPage;



export const selectTasksData = (state) => state.task.tasks;
export const selectNews = (state) => state.task.news;
export const selectNewsContent = (state) => state.task.news.content;
export const selectNewsLast = (state) => state.task.news.last;
export const selectNewsEmpty = (state) => state.task.news.empty;
export const selectNewsPageNumber = (state) => state.task.news.number;
export const selectNewsTotalElements = (state) => state.task.news.totalElements;
export const selectNewsTotalPages = (state) => state.task.news.totalPages;
export const selectDailyCheckCode = (state) => state.task.tasks.daily_check_codes[0];
export const selectDailyCheckCodes = (state) => state.task.tasks.daily_check_codes;
export const selectTasks = (state) => state.task.tasks.tasks;
export const selectFirst = (state) => state.task.tasks.first;
export const selectLast = (state) => state.task.tasks.last;
export const selectTotalElements = (state) => state.task.tasks.totalElements;
export const selectTotalPages = (state) => state.task.tasks.totalPages;
export const selectDailyCodeTaskStatus = (state) => state.task.tasks.daily_check_codes[0].status

export const selectReferrals = (state) => state.referral.referrals;
export const selectFirstLevelReferrals = (state) => state.referral.referrals.referrals;
export const selectSecondLevelReferrals = (state) => state.referral.referrals['referrals-second-level'];
export const selectReferralsFirst = (state) => state.referral.referrals.first;
export const selectReferralsLast = (state) => state.referral.referrals.last;
export const selectReferralsTotalElements = (state) => state.referral.referrals.totalElements;
export const selectReferralsTotalPages = (state) => state.referral.referrals.totalPages;
export const selectReferralsEmpty = (state) => state.referral.referrals.empty;

export const selectReferralCurrentPageNumber = (state) => state.referral.referrals.currentPage;

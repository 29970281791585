import {
    SET_QUEST,
} from './questActions';

const initialState = {
    quest: null,
};

const questReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUEST:
            return {
                ...state,
                quest: action.payload,
            };
        default:
            return state;
    }
};

export default questReducer;

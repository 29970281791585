import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {
    selectStatistics, selectTotalPoints, selectUserCheckInConsecutiveDays,
    selectUserConsecutiveDays,
    selectUserLeaderBoardPlace, selectUserLevel, selectUserName,
    selectUserQuestConsecutiveDays,
    selectUserSecondLevelReferrals,
} from "../../entities/User/model/userSelectors";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {getStatistics} from "../../entities/User/model/userActions";
import {translate} from "../../shared/utils/translator";
const StatisticsPage = () => {
    const dispatch = useDispatch();
    // TEST MODE
    // const userData = {
    //     nickname: "JohnDoe",
    //     level: 5,
    //     check_in_consecutive_days: 3,
    //     consecutive_days: 7,
    //     quest_consecutive_days: 2,
    //     leader_board_place: 12,
    //     total_points: 1500,
    //     second_level_referrals: 5,
    //     total_users: 1000,
    //     new_users: 50,
    //     active_users: 200,
    //     total_user_points: 50000,
    //     average_user_points: 50,
    // };
    // TEST MODE

    const nickname = useSelector(selectUserName);
    const level = useSelector(selectUserLevel);
    const total_points = useSelector(selectTotalPoints);
    const check_in_consecutive_days = useSelector(selectUserCheckInConsecutiveDays);
    const consecutive_days = useSelector(selectUserConsecutiveDays);
    const quest_consecutive_days = useSelector(selectUserQuestConsecutiveDays);
    const leader_board_place = useSelector(selectUserLeaderBoardPlace);
    const second_level_referrals = useSelector(selectUserSecondLevelReferrals);
    const statistics = useSelector(selectStatistics);

    const [loading, setLoading] = useState(false);  // Loading state

    useEffect(() => {
        const fetchStatisticsData = async () => {
            if (!statistics) {
                setLoading(true);  // Start loading
                await dispatch(getStatistics());
                setLoading(false); // End loading whether success or failure
            }
        };

        fetchStatisticsData();
    }, [dispatch]);

    if (loading) {
        return (
            <LoadingSpinner />
        );
    }


    if (!statistics) {
        return (
            <section className="user-statistic-container">
                {translate("cannot-get-statistics")}
            </section>
        );
    }
    return (
      <section className="user-statistic-container no-scrollbar">
          <div className="user-profile-container">
              <h2>User Profile</h2>
              <p><strong>Nickname:</strong> {nickname}</p>
              <p><strong>Level:</strong> {level}</p>
              <p><strong>Total Points:</strong> {total_points}</p>
          </div>

          <div className="engagment-stats-container">
              <h2>Engagement Stats</h2>
              <p><strong>Check-in Streak:</strong> {check_in_consecutive_days} days</p>
              <p><strong>Active Days:</strong> {consecutive_days} days</p>
              <p><strong>Quest Streak:</strong> {quest_consecutive_days} days</p>
          </div>

          <div className="leaderboard-container">
              <h2>Leaderboard</h2>
              <p><strong>Leaderboard Position:</strong> {leader_board_place}</p>
          </div>

          <div className="refferal-stats-container">
              <h2>Referral Stats</h2>
              <p><strong>First-level Referrals:</strong> {second_level_referrals}</p>
              <p><strong>Second-level Referrals:</strong> 0 </p>
          </div>

          {/* User Engagement Overview Section */}
          <div className="user-engagment-overview-container">
              <h2>User Engagement Overview</h2>
              <p><strong>Total Users:</strong> {statistics['total-users']}</p>
              <p><strong>New Users:</strong> {statistics['new-users']}</p>
              <p><strong>Active Users:</strong> {statistics['active-users'] || 0}</p>
              <p><strong>Total User Points:</strong> {statistics['total-user-points'] || 0}</p>
              <p><strong>Average User Points:</strong> {statistics['average-user-points'] || 0}</p>
          </div>
      </section>
    );
}

export default StatisticsPage;

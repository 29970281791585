import CXPLogo from "../../shared/assets/images/CXPLogo.jpg";
import CXPLogoUnActive from "../../shared/assets/images/CXPLogo_unactive.jpg";
import React, {useEffect, useState} from "react";
import DigitScroller from "./components/DigitScroller";
import "./style.css";
import DayTimer from "./components/DayTimer";
import {useDispatch, useSelector} from "react-redux";
import {selectDailyCheckCode} from "../../entities/Task/model/taskSelectors";
import {selectTotalPoints, selectUserConsecutiveDays, selectUserToken} from "../../entities/User/model/userSelectors";
import {validateUserTaskById} from "../../entities/Task/model/taskActions";
import {setDailyCodeTaskStatus} from "../../entities/Task/model/taskActions";
import {translate} from "../../shared/utils/translator";

export default function HomePage() {
    const dispatch = useDispatch();
    const totalPoints = useSelector(selectTotalPoints);
    const [displayPoints, setDisplayPoints] = useState(totalPoints);
    const [isAnimating, setIsAnimating] = useState(false);

    // Helper function to handle the animation
    const animatePoints = (targetPoints) => {
        setIsAnimating(true);
        const animationDuration = 1000; // 1 second
        const animationInterval = 10;  // 10 updates per second
        const startTime = Date.now();

        // Show random digits of the same length as the target points
        let randomDigits = Array.from({ length: String(targetPoints).length }, () => Math.floor(Math.random() * 10));
        setDisplayPoints(randomDigits.join(''));

        const interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            if (elapsedTime >= animationDuration) {
                clearInterval(interval);
                setDisplayPoints(targetPoints);
                setIsAnimating(false);
            } else {
                let randomDigits = Array.from({ length: String(targetPoints).length }, () => Math.floor(Math.random() * 10));
                setDisplayPoints(randomDigits.join(''));
            }
        }, animationInterval);
    };

    // Update displayPoints when totalPoints changes
    useEffect(() => {
        if (totalPoints !== displayPoints) {
            animatePoints(totalPoints);
        }
    }, [totalPoints]); // Only depend on totalPoints

    // Trigger animation on the initial render
    useEffect(() => {
        if (totalPoints) {
            animatePoints(totalPoints);
        }
    }, []); // Empty dependency array triggers on initial render

    const token = useSelector(selectUserToken);
    const daily_code_task = useSelector(selectDailyCheckCode);
    const daily_code_task_content = daily_code_task.content;
    const consecutiveDays = useSelector(selectUserConsecutiveDays);
    const [currentAward, setCurrentAward] = useState(1000);
    const [digits, setDigits] = useState([0, 0, 0, 0]);
    const [dailyCodeTaskDone, setDailyCodeTaskDone] = useState(daily_code_task.status === 'DONE');
    const [inputedCodeRight, setInputedRightCode] = useState(false);

    const checkDigits = () => {
        console.log(`Digits join: ${digits.join('')} daily_code_task_content: ${daily_code_task_content}`);
        if (digits.join('') === daily_code_task_content) {
            const result = dispatch(validateUserTaskById(daily_code_task.id, token, digits.join('')))
            console.log('Enter code matched:', digits.join(''));
            console.log("checkDigits result:", result);
            // reset digigts
            setDigits([0, 0, 0, 0]);
            if (!result) {
                // Display ERROR to user
                console.error("Inputed code is not correct");
                return;
            }
            // dispatch(setUserConsecutiveDays(consecutiveDays + 1));
            dispatch(setDailyCodeTaskStatus('DONE'))
            setDailyCodeTaskDone(result);

        }
    };

    useEffect(() => {
        if (digits.join('') === daily_code_task_content) {
            setInputedRightCode(true);
            return;
        }
        setInputedRightCode(false);
    }, [digits, daily_code_task_content]);

    const updateDigit = (index, value) => {
        const newDigits = [...digits];
        newDigits[index] = value;
        setDigits(newDigits);
    };

    useEffect(() => {
        console.log("consecutiveDays: ", consecutiveDays);
        const days = daily_code_task.status === 'DONE' ? consecutiveDays + 1 : consecutiveDays;
        const result = days > 30 ? 1000 + 31 * 100 : 1000 + days * 100;
        setCurrentAward(result);
    }, [consecutiveDays, daily_code_task.status ]);

    return (
        <section className="home-page-container">
            <h1 className={`total-points ${isAnimating ? 'animating' : ''}`}>{displayPoints}  CMP</h1>
            <div className="collect-section">
            {dailyCodeTaskDone ?
                <div>
                    <img src={CXPLogoUnActive} alt="Collect CXP" className="collect-logo" />
                    <div className="collect-text">
                        <span className="collect-text-top">{translate("collect-top-text-tomorrow", {points: currentAward})}</span>
                    </div>
                </div>
                :
                inputedCodeRight ?
                    <div onClick={() => checkDigits()}>
                        <img src={CXPLogo} alt="Collect CXP" className="collect-logo" />
                        <div className="collect-text">
                            <span className="collect-text-top">{translate("collect-top-text-today", {points: currentAward})}</span>
                        </div>
                    </div>
                    :
                    <div>
                        <img src={CXPLogoUnActive} alt="Collect CXP" className="collect-logo" />
                        <div className="collect-text">
                            <span className="collect-text-top">{translate("collect-top-text-today-inactive", {points: currentAward})}</span>
                        </div>
                    </div>
            }
            </div>
            <p className="reward-text">{translate("reward-text-home-page")}</p>
            <div className="code-section">
                {!inputedCodeRight || dailyCodeTaskDone ?
                    <div className="code-display">
                    {digits.map((digit, index) => (
                        <DigitScroller key={index} digit={digit} index={index} updateDigit={updateDigit} />
                    ))}
                </div>
                    :
                    <p>{translate("reward-text-code-section-home-page")}</p>
                }
            </div>
            {!dailyCodeTaskDone && <p className="enter-code-text">{translate("enter-code-text-home-page", {daily_code_task_content: daily_code_task_content})}<span className="info-icon">?</span></p>}
            <DayTimer />
        </section>
    );
}

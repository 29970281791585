import {fetchUserReferralsFirstLevel} from '../api/referralActions';

// Action Types
export const SET_REFERRALS = 'SET_REFERRALS';

// Action Creators
export const setReferrals = (referrals) => ({
    type: SET_REFERRALS,
    payload: referrals,
});

export const getUserReferrals = (page, size) => async (dispatch) => {
    try {
        const firstLevelResponse = await fetchUserReferralsFirstLevel(page, size);
        console.log("getUserReferrals firstLevelResponse: ", firstLevelResponse)

        if (firstLevelResponse) {
            dispatch(setReferrals(firstLevelResponse));
        }
    } catch (error) {
        console.error('Failed to fetch referrals', error);
    }
};

import axiosInstance from "../../../shared/utils/api";

export const fetchUserTasks = async (page, size) => {
    try {
        const response = await axiosInstance.get(`/user-task?page=${page}&size=${size}`)
        console.log('fetchUserTasks', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching user tasks:', error);
        return null;
    }
}
export const fetchNews = async (page, size) => {
    try {
        const response = await axiosInstance.get(`/news?page=${page}&size=${size}`);
        console.log('fetchNews', response.data.data);
        return response.data.data;
    } catch (e) {
        console.error("Failed to fetch news: ", e);
        throw e;
    }
}

export const fetchMarkAsReadNews = async (newsId) => {
    try {
        const response = await axiosInstance.post(`/news/mark-as-read/${newsId}`);
        console.log("fetchMarkAsReadNews", response.data.data);
        return response.data.data;
    } catch (e) {
        console.error("Failed to fetch mark as read news", e);
        return null;
    }
}
export const fetchValidateUserTaskById = async (taskId, token, code) => {
    const postData = {
      code: code
    };
    try {
        const response = await axiosInstance.post(`/user-task/${taskId}/check`, postData)
        console.log('validateUserTaskById', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching user tasks:', error);
        return null;
    }
}

import React, {useEffect, useState} from 'react';
import {
    selectReferrals
} from '../model/referralSelectors';
import {useDispatch, useSelector} from "react-redux";
import "./style.css";
import ReferralItem from "./ReferralItem";
import {getUserReferrals} from "../model/referralActions";
import {translate} from "../../../shared/utils/translator";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";

const ReferralList = () => {
    const dispatch = useDispatch();
    const referrals = useSelector(selectReferrals);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchReferralsData = async () => {
            if (!referrals || referrals.content.length === 0) {
                setLoading(true);  // Start loading
                await dispatch(getUserReferrals(0, 20));
                setLoading(false); // End loading whether success or failure
            }
        };

        fetchReferralsData();
    }, [dispatch]);

    if (loading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!referrals || referrals.content.length === 0) {
        return (
            <div className="referral-list-empty">
                You currently have no invited frens.
                {/*<div className="news-logo-section">*/}
                {/*    <img src={CMLogoFrens} alt="CM_tasks_Logo" className="cm_tasks_logo" />*/}
                {/*</div>*/}
                {/*<p className="reward-text">{translate("news-list-empty")}</p>*/}
            </div>
        )
    }

    const requestMoreReferrals = () => {
        if (!referrals.last) {
            dispatch(getUserReferrals(referrals.currentReferralPage + 1, 20));
        }
    };
    return (
        <>
            <p className="frens-quantity">{translate("referral-list-title")}<sup>{referrals.totalElements}</sup></p>
            {referrals.content.map((referral, index) => {
                return (
                    <div className="referral-item" key={index}>
                        <ReferralItem referral={referral} />
                    </div>
                );
            })}
            {
                !referrals.last && <button className="add-more-referrals-button" onClick={requestMoreReferrals}>See more your Frens</button>
            }
        </>
    );
};

export default ReferralList;

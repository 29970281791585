import React, {useEffect, useState} from "react";
import NewsItem from "./NewsItem";
import {useDispatch, useSelector} from "react-redux";
import {
    selectNews
} from "../model/taskSelectors";
import {getMoreNews, getNews} from "../model/taskActions";
import {translate} from "../../../shared/utils/translator";
import CMLogoFrens from "../../../shared/assets/images/Logo Small.jpg";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";

const NewsList = () => {
    const dispatch = useDispatch();
    const news = useSelector(selectNews);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchNewsData = async () => {
            if (!news || news.content.length === 0) {
                setLoading(true);  // Start loading
                await dispatch(getNews(0, 20));
                setLoading(false); // End loading whether success or failure
            }
        };

        fetchNewsData();
    }, [dispatch]);

    if (loading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!news || news.content.length === 0) {
        return (
            <div className="news-list-empty">
                <div className="news-logo-section">
                    <img src={CMLogoFrens} alt="CM_tasks_Logo" className="cm_tasks_logo" />
                </div>
                <p className="reward-text">{translate("news-list-empty")}</p>
            </div>
        )
    }
    console.log("News: ", news);
    const handleLoadMoreNews = () => {
        if (!news.last && news.pageNumber <= news.totalPages) {
            dispatch(getMoreNews(news.pageNumber + 1, 20));
        }
    }
    return (
      <div>
          {news.content.map((item, index) => (
              <div className="news-item" key={index}>
                  <NewsItem news={item} />
              </div>
          ))}
          {
              !news.last && (
                  <button className="load-more-news" onClick={handleLoadMoreNews}>
                      {translate("load-more-news")}
                  </button>
              )
          }
      </div>
    );
}
export default NewsList;

import axiosInstance from "../../../shared/utils/api";

export const fetchQuests = async () => {
    try {
        const response = await axiosInstance.get(`/quests`)
        console.log('fetchQuests', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching quests:', error);
        return null;
    }
}

export const fetchCheckQuest = async (questId, answer) => {
    try {
        const response = await axiosInstance.post('/quests/check', {
            questId: typeof parseInt(questId, 10),
            answer: typeof answer
        })
        console.log("fetchCheckQuest response", response.data.data);
        return response.data.data;
    } catch (e) {
        console.error("Failed to fetch check quest", e);
        throw e;
    }
}

import "./App.css";
import useTelegram from "../shared/hooks/useTelegram";
import {useEffect, useState} from "react";
import "../shared/assets/styles/fonts.css";
import AppRoutes from "./routes/AppRoutes";
import {initializeApp} from "./actions/initializeApp";
import {useDispatch, useSelector} from "react-redux";
import LoadingPage from "../shared/components/LoadingPage";
import {selectCurrentLanguage} from "../entities/Language/model/languageSelectors";
import {setLanguage as setTranslatorLanguage} from "../shared/utils/translator";

export default function App() {
    const {onReady, onExpand, initData, onSetHeaderColor, disableVerticalSwipes} = useTelegram();
    const [isLoading, setIsLoading] = useState(true);
    const [invite, setInvite] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const checkPath = () => {
            const path = window.location.pathname;
            console.log("Path window.location.pathname", path);
            if (path.startsWith('/miniapp')) {
                console.log("Path window.location.pathname", path);
                const inviteCode = path.replace('/miniapp', '');
                console.log("inviteCode", inviteCode);
                setInvite(inviteCode);
            } else {
                setInvite("")
            }
        };

        checkPath();

        const handlePopState = () => {
            checkPath();
        };

        window.addEventListener('popstate', handlePopState);

        window.addEventListener('hashchange', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('hashchange', handlePopState);
        };
    }, []);

    console.log("Environment: ", process.env.REACT_APP_ENVIRONMENT);

    const language = useSelector(selectCurrentLanguage);

    useEffect(() => {
        console.log("setTranslatorLanguage: ", language);
        setTranslatorLanguage(language); // Change the language based on the Redux store
    }, [language]);

    console.log("initData", initData);

    useEffect(() => {
        // prepare telegram data
        onReady();
        // expand app to full screen
        onExpand();
        // set Telegram Mini App Header color
        onSetHeaderColor('#FFF');
        // disables vertical swipes to close or minimize the Mini App
        disableVerticalSwipes();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            console.log("App invite: ", invite);
            await dispatch(initializeApp(initData, invite));
            setIsLoading(false);
        };
        if (invite !== null) {
            fetchData();
        }
    }, [dispatch, invite]);

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
    <main className="App">
        <AppRoutes />
    </main>
  );
}

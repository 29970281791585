const initialState = {
    tasks: [],
    news: null
};

const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_TASKS":
            return {
                ...state,
                tasks: action.payload,
            };
        case "SET_NEWS":
            return {
                ...state,
                news: action.payload
            };
        case "SET_DAILY_CODE_TASK_STATUS": {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    daily_check_codes: [
                        {
                            ...state.tasks.daily_check_codes[0],
                            status: action.payload
                        },
                        ...state.tasks.daily_check_codes.slice(1)
                    ]
                }
            };
        }
        case "SET_TASK_STATUS":
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    tasks: state.tasks.tasks.map(task =>
                        task.id === action.payload.taskId
                            ? { ...task, status: action.payload.status }
                            : task
                    )
                }
            };
        case "UPDATE_TASKS":
            const newTasks = action.payload;

            return {
                ...state,
                tasks: {
                    daily_check_codes: [
                        // Merge old daily_check_codes with new ones, avoiding duplicates
                        ...(state.tasks.daily_check_codes || []),
                        ...newTasks.daily_check_codes.filter(newTask =>
                            !state.tasks.daily_check_codes.some(existingTask => existingTask.id === newTask.id)
                        )
                    ],
                    tasks: [
                        ...(state.tasks.tasks || []),
                        ...newTasks.tasks.filter(newTask =>
                            !state.tasks.tasks.some(existingTask => existingTask.id === newTask.id)
                        )
                    ],
                    // Update pagination and other fields with the latest data
                    empty: newTasks.empty,
                    first: newTasks.first,
                    last: newTasks.last,
                    number: newTasks.number,
                    totalElements: newTasks.totalElements,
                    totalPages: newTasks.totalPages,
                },
            };
        case "UPDATE_NEWS":
            const newNews = action.payload;
            console.log("UPDATE_NEWS: ", newNews);
            const mergedContent = [
                ...state.news.content,
                ...newNews.content.filter(newItem =>
                    !state.news.content.some(existingItem => existingItem.id === newItem.id) // filter out duplicates
                )
            ];
            return {
                ...state,
                news: {
                    content: mergedContent, // merged news content without duplicates
                    empty: newNews.empty,
                    first: newNews.first,
                    last: newNews.last,
                    number: newNews.number,
                    totalElements: newNews.totalElements,
                    totalPages: newNews.totalPages,
                },
            };
        default:
            return state;
    }
};

export default taskReducer;

import iconStatistic from "../assets/images/icon_statistik.svg";
import iconSettings from "../assets/images/settings.svg";
import "./Header.css";
import {useSelector} from "react-redux";
import {selectTotalPoints, selectUserLevel, selectUserName} from "../../entities/User/model/userSelectors";
import {useEffect, useState} from "react";
import {translate} from "../utils/translator";

const Header = ({goToPage}) => {
    const totalPoints = useSelector(selectTotalPoints);
    const username = useSelector(selectUserName);
    const userPhoto = null;
    // const totalPoints ='1200750';
    // const username = 'ed475';
    const userLevel = useSelector(selectUserLevel);

    const displayPhoto = userPhoto ? (
        <img src={userPhoto} alt="User Photo" className="user-photo" />
    ) : (
        <div className="user-initials">
            {username.charAt(0).toUpperCase() + username.charAt(1).toUpperCase()}
        </div>
    );

    const [animatePoints, setAnimatePoints] = useState(false);

    useEffect(() => {
        // Trigger animation whenever totalPoints changes
        setAnimatePoints(true);
        const timeout = setTimeout(() => setAnimatePoints(false), 1000); // Animation duration is 1 second
        return () => clearTimeout(timeout);
    }, [totalPoints]);

    return (
        <header>
            <div className="header-left">
                {displayPhoto}
                <div className="user-info">
                    <div className="username">{username}</div>
                    <div className={`user-stats ${animatePoints ? "animate-points" : ""}`}>
                        {translate('header-user-stats', {userLevel, totalPoints})}
                    </div>
                </div>
            </div>
            <div className="header-right">
                <img src={iconStatistic} alt="Statistics" className="icon" onClick={() => goToPage("Stats")} />
                <img src={iconSettings} alt="Settings" className="icon" onClick={() => goToPage("Settings")} />
            </div>
        </header>
    )
}

export default Header;

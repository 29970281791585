// Определяем конфигурации для разных окружений
const config = {
    QA_ENVIRONMENT: {
        apiUrl: 'https://api.production.example.com',
    },
    QA_ENVIRONMENT: {
        apiUrl: 'https://cma-qa.collective.money/app/v1',
    }
};
export const getEnvironmentConfig = () => {
    console.log(`getEnvironmentConfig environment: ${process.env.REACT_APP_ENVIRONMENT}`);
    // В зависимости от значения REACT_APP_ENVIRONMENT, возвращаем нужную конфигурацию
    if (process.env.REACT_APP_ENVIRONMENT?.trim() === 'QA_ENVIRONMENT') {
        return config.QA_ENVIRONMENT;
    } else {
        return config.QA_ENVIRONMENT;
    }
};

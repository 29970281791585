import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import userReducer from '../../entities/User/model/userReducer';
import languageReducer from '../../entities/Language/model/languageReducer';
import taskReducer from "../../entities/Task/model/taskReducer";
import referralReducer from "../../entities/Referral/model/referralReducer";
import questReducer from "../../entities/Quest/model/questReducer";

const rootReducer = combineReducers({
    user: userReducer,
    language: languageReducer,
    task: taskReducer,
    referral: referralReducer,
    quest: questReducer,
});

const middleware = [thunk];

const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);

export default store;

import React from "react";
import "./LoadingSpinner.css";
const LoadingSpinner = () => {
    return (
        <div className="loading-spinner-container">
            <div className="quest-spinner"></div>
        </div>
    );
}

export default LoadingSpinner;

export const selectUser = (state) => state.user;
export const selectTotalPoints  = (state) => state.user.total_points;
export const selectUserName = (state) => state.user.nickname;
export const selectUserTimezone = (state) => state.user.timezone;
export const selectUserLocale = (state) => state.user.locale;
export const selectUserLevel = (state) => state.user.level;
export const selectUserCheckInConsecutiveDays = (state) => state.user['check-in-consecutive-days'];
export const selectUserQuestConsecutiveDays = (state) => state.user['quest-consecutive-days'];
export const selectUserLeaderBoardPlace = (state) => state.user['leader-board-place'];
export const selectUserSecondLevelReferrals = (state) => state.user['second-level-referrals'];
export const selectUserId = (state) => state.user.id;

export const selectUserInvite = (state) => state.user.invite;

export const selectUserConsecutiveDays = (state) => state.user['consecutive-days'];
export const selectUserToken = (state) => state.user.userToken;
export const selectStatistics = (state) => state.user.statistics;


import {useEffect, useState} from "react";
import "./style.css";
import {setTaskStatus, validateUserTaskById} from "../model/taskActions";
import useTelegram from "../../../shared/hooks/useTelegram";
import {useDispatch, useSelector} from "react-redux";
import {selectUserToken} from "../../User/model/userSelectors";
const TaskItem = ({ task }) => {
    const dispatch = useDispatch();
    const token = useSelector(selectUserToken);
    const {onOpenLink} = useTelegram();
    const { id, title, awards, status, content, expiredDate } = task;
    const [timeRemaining, setTimeRemaining] = useState("");

    useEffect(() => {
        if (expiredDate) {
            // Calculate time remaining
            const interval = setInterval(() => {
                const now = new Date().getTime();
                const expiryTime = new Date(expiredDate).getTime();
                const distance = expiryTime - now;

                if (distance <= 0) {
                    setTimeRemaining("Expired");
                    clearInterval(interval);
                } else {
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    setTimeRemaining(`${hours}:${minutes}:${seconds}`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [expiredDate]);

    const handleTaskClick = () => {
        if (status === "START") {
            // Redirect user to task content
            // Use useTelegram openLink
            onOpenLink(content);

            dispatch(setTaskStatus(id, "PENDING"));

            // Start polling to validate task status
            const interval = setInterval(async () => {
                const result = await dispatch(validateUserTaskById(id, token));
                if (result) {
                    clearInterval(interval);
                    dispatch(setTaskStatus(id, "DONE"));
                }
            }, 3000); // Poll every 3 seconds
        }
    };

    return (
        <>
            <div className="full-task-button" onClick={handleTaskClick}></div>
            <div className="task-icon">
                {
                    title.startsWith('Subscribe on Telegram') &&
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_23_27)">
                            <path d="M18 36C8.0586 36 0 27.9414 0 18C0 8.0586 8.0586 0 18 0C27.9414 0 36 8.0586 36 18C36 27.9414 27.9414 36 18 36ZM12.402 20.106L12.4254 20.0934L13.9914 25.2594C14.193 25.8192 14.4702 25.92 14.8068 25.8732C15.1452 25.8282 15.3234 25.6464 15.5448 25.434L17.6832 23.3676L22.2732 26.766C23.112 27.2286 23.715 26.9892 23.9238 25.9884L26.9064 11.9088C27.2358 10.5984 26.6598 10.0728 25.6428 10.4904L8.1234 17.2584C6.9282 17.7372 6.9354 18.4068 7.9074 18.7038L12.402 20.106Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_23_27">
                                <rect width="36" height="36" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    // <img src={TelegramIcon} alt="Telegram" />
                }
                {
                    title.startsWith('Subscribe on YouTube')
                    &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                    </svg>
                }
                {
                    title.startsWith('Join Discord')
                    &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                    <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                    </svg>
                }
                {
                    title.startsWith('Subscribe on X')
                    &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                }
            </div>
            <div className="task-details">
                <h3 className="task-title">{title}</h3>
                <div className="task-meta">
                    <p className="task-awards">+ {awards} XCP</p>
                    {expiredDate && <span className="task-expiry">{timeRemaining}</span>}
                </div>
            </div>
            <div className="task-item-button">
                {status === "START" && (
                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="task-arrow">
                        <path d="M1 19L10 10L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )}
                {status === "PENDING" && (
                        <div className="loading-spinner">
                            <div className="spinner"></div>
                        </div>
                    )
                }
                {status === "DONE" && (
                    <div className="task-completed-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="white" />
                            <path d="M6 12L10 16L18 8" stroke="#6E8BF1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                )}
            </div>
        </>
    );
};

export default TaskItem;

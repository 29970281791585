import {fetchAuthUser, fetchCurrentUser, fetchStatistics} from "../api/userActions";
import {
    SET_CONSECUTIVE_DAYS, SET_STATISTICS,
    SET_USER,
    SET_USER_TOKEN, SET_USER_TOTAL_POINTS
} from "./actionTypes";

// Action Creators
export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

export const setUserConsecutiveDays = (consecutiveDays) => ({
    type: SET_CONSECUTIVE_DAYS,
    payload: consecutiveDays
})

export const setStatistics = (statistics) => ({
    type: SET_STATISTICS,
    payload: statistics
})

// export const setAuthUser = (user) => ({
//     type: SET_AUTH_USER,
//     payload: user,
// });

export const setUserToken = (token) => ({
    type: SET_USER_TOKEN,
    payload: token,
});

export const setUserTotalPoints = (totalPoints) => {
    console.log("Action: Dispatching SET_USER_TOTAL_POINTS with payload:", totalPoints);
    return {
        type: SET_USER_TOTAL_POINTS,
        payload: totalPoints,
    };
};

export const setCurrentUser = () => async (dispatch) => {
    try {
        const response = await fetchCurrentUser();
        console.log("setCurrentUser response: ", response);
        const updatedUserData = {
            ...response, // Copy all the other properties
            total_points: response["total-points"], // Create the new key "total_points"
        };
        delete response["total-points"];
        dispatch(setUser(updatedUserData));
        console.log('setCurrentUser setUser: ', updatedUserData);
    } catch (error) {
        console.error('setCurrentUser error:', error);
        throw error;
    }
};

export const authUser = (userData, invite) => async () => {
    try {
        const userToken = await fetchAuthUser(userData, invite);
        console.log("authUser: ", userToken);
        return userToken;
    } catch (error) {
        console.error('Authentication error:', error);
        throw error; // Optional: Rethrow to handle it further up the call stack if needed
    }
};

export const getStatistics = () => async (dispatch) => {
    try {
        const response = await fetchStatistics();
        if (response) {
            dispatch(setStatistics(response));
        }
    } catch (e) {
        console.error("Failed to get statistics: ", e);
        throw e;
    }
}


import React, {useState} from 'react';
import ReferralList from '../../entities/Referral/ui/ReferralList';
import CMLogoFrens from "../../shared/assets/images/Logo Small.jpg";
import "./style.css";
import useTelegram from "../../shared/hooks/useTelegram";
import {useSelector} from "react-redux";
import {selectUserInvite} from "../../entities/User/model/userSelectors";
import {translate} from "../../shared/utils/translator";
import ToastMessage from "../../shared/components/ToastMessage";

const FrensPage = () => {
    const {onOpenTelegramLink} = useTelegram();
    const userInvite = useSelector(selectUserInvite);
    const inviteLink = `https://t.me/cm2accbot?start=app-${userInvite}`;

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleCopyClick = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => {
                setToastMessage(translate("text-copied-to-clipboard"));
                setShowToast(true);
                console.log('Text copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleForwardTelegram = () => {
        const url = `https://t.me/share/url?url=&text=${encodeURIComponent(inviteLink)}`;
        onOpenTelegramLink(url)
    }
    return (
        <section className="frens-page-container no-scrollbar">
            <h1 className="frens-title">{translate("frens-title-frens-page")}</h1>
            <div className="frens-logo-section">
                <img src={CMLogoFrens} alt="CM_tasks_Logo" className="cm_tasks_logo" />
            </div>
            <p className="reward-text">{translate("reward-text-frens-page")}</p>
            <ReferralList />
            <div className="invite-buttons">
                <button className="invite-button main-button" onClick={() => handleForwardTelegram()}>{translate("invite-frens-frens-page")}</button>
                <button className="invite-button icon-button" onClick={() => handleCopyClick()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none">
                        <path d="M14 8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22H14C15.103 22 16 21.103 16 20V10C16 8.897 15.103 8 14 8Z" fill="white"/>
                        <path d="M20 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z" fill="white"/>
                    </svg>
                </button>
            </div>
            <ToastMessage
                text={toastMessage}
                duration={2000}
                show={showToast}
                onClose={handleCloseToast} />
        </section>
    );
};

export default FrensPage;

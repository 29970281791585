import TaskList from "../../entities/Task/ui/TaskList";
import React from "react";
import CMLogoTasks from "../../shared/assets/images/Logo Small.jpg";
import "./style.css";
import {translate} from "../../shared/utils/translator";
import {useSelector} from "react-redux";
import {selectTasks} from "../../entities/Task/model/taskSelectors";

const TaskPage = () => {
    const tasks = useSelector(selectTasks);
    const startStatusCount = tasks.filter(task => task.status === "START").length;
    return (
        <section className="tasks-page-container no-scrollbar">
            <h1 className="tasks-title">{translate("tasks-title-task-page")} {startStatusCount}</h1>
            <div className="task-logo-section">
                <img src={CMLogoTasks} alt="CM_tasks_Logo" className="cm_tasks_logo" />
            </div>
            <p className="reward-text">{translate("reward-text-task-page")}</p>
            <TaskList />
        </section>
    );
}

export default TaskPage;
